[data-bs-theme='dark'] {
    .rdrDefinedRangesWrapper{
        background-color:  $app-sidebar-dark-bg-color;
    }
  .rdrStaticRange {
    font-size: 12px;
    width: 226px;
    border-right: solid 1px #eff2f7;
    background: transparent;
  }
}
